<template>
  <div class="container text-center overflow-hidden mx-auto max-w-xl p-8 sm:pt-0">
    <!-- <dark-light-mode /> -->

    <canvas
      ref="canvas"
      style="width:100vw; height:100vh; position:fixed; top:0; left:0; right:0; bottom:0;"
    />

    <div class="pointer-events-none relative">
      <h1 class="text-7xl sm:text-9xl pb-5 text-white font-family-oswald">
        daze<span class="text-pink">mx</span>
      </h1>
      <h2 class="text-2xl sm:text-3xl text-white sm:-mr-5">
        I make websites, occasionally*
      </h2>
      <h3 class="py-5 text-lg text-white text-opacity-60">
        &nbsp;"I've been making websites since I&nbsp;could&nbsp;type."
      </h3>
      <p class="text-white text-opacity-60 font-thin sm:text-lg">
        I'm now {{ $moment().diff(birth, 'years') }} years old &
        have over {{ $moment().diff(firstJob, 'years') }} years of professional
        experience designing, developing & maintaining websites.
        {{ $moment().diff(firstJob, 'days') | formatNumber }} days of programming, to be exact...
      </p>
    </div>
    <t-button
      class="mt-6 sm:mt-8 pink relative"
      @click="modal=true"
    >
      👋 Say hello
    </t-button>

    <vodal
      :show="modal"
      :close-on-esc="true"
      animation="flip"
      @hide="modal=false"
    >
      <contact-form
        @close="modal=false"
      />
    </vodal>

    <p class="text-white text-opacity-60 text-xs pt-8 sm:pt-14">
      I also take photos...
      <t-button
        class="outline ml-2 px-2 mt-2 text-xs"
        @click="openWindow('https://www.dzephotography.com')"
      >
        Photography portfolio
      </t-button>
    </p>

    <footer class="absolute bottom-0 left-0 right-0 pb-6 text-xs font-light pointer-events-none">
      <ul
        :class="'grid grid-cols-2 sm:flex justify-center items-center ' +
          'text-white text-opacity-60 w-max mx-auto'"
      >
        <li class="py-1 sm:py-0 px-2 sm:px-3 text-right">
          *basically all the time
        </li>
        <li class="py-1 sm:py-0 px-2 sm:px-3 text-left">
          &copy; {{ $moment().year() }} {{ $siteName }}
        </li>
        <li class="py-1 sm:py-0 text-right">
          <a
            href="https://github.com/dazecoop"
            target="_blank"
            class="py-2 px-3 hover:text-white transition-colors duration-300 pointer-events-auto"
          >
            <font-awesome-icon
              :icon="['fab', 'github']"
              size="lg"
            />
          </a>
        </li>
        <li class="pt-1 sm:py-0 text-left">
          <a
            href="https://twitter.com/dazecoop"
            target="_blank"
            class="py-2 px-3 hover:text-white transition-colors duration-300 pointer-events-auto"
          >
            <font-awesome-icon
              :icon="['fab', 'twitter']"
              size="lg"
            />
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import ContactForm from '../components/ContactForm';
// import DarkLightMode from '../components/DarkLightMode';
import Vue from 'vue';
import Vodal from 'vodal'

import WebGLFluid from 'webgl-fluid'

Vue.component(Vodal.name, Vodal);

export default {
  name: 'Index',
  components: {
    ContactForm,
    // DarkLightMode,
  },
  data() {
    return {
      firstJob: 'Tue, 01 Jan 2002 00:00:00 EST',
      birth: 'Thu, 11 Dec 1986 00:00:00 EST',
      modal: false,
    };
  },
  metaInfo() {
    return {
      title: this.$siteName,
    };
  },
  mounted() {
    WebGLFluid(this.$refs.canvas, {
      IMMEDIATE: true, // Whether to trigger multiple random splats when initialized
      TRIGGER: 'hover', // Can be change to 'click'
      SIM_RESOLUTION: 80,
      DYE_RESOLUTION: 512,
      CAPTURE_RESOLUTION: 128,
      DENSITY_DISSIPATION: .28,
      VELOCITY_DISSIPATION: .18,
      PRESSURE: 0.8,
      PRESSURE_ITERATIONS: 2,
      CURL: 0,
      SPLAT_RADIUS: 0.25,
      SPLAT_FORCE: 4000,
      SHADING: true,
      COLORFUL: true,
      COLOR_UPDATE_SPEED: 10,
      PAUSED: false,
      BACK_COLOR: { r: 10, g: 10, b: 10 },
      TRANSPARENT: false,
      BLOOM: true,
      BLOOM_ITERATIONS: 5,
      BLOOM_RESOLUTION: 128,
      BLOOM_INTENSITY: 0.4,
      BLOOM_THRESHOLD: 0.6,
      BLOOM_SOFT_KNEE: 0.7,
      SUNRAYS: true,
      SUNRAYS_RESOLUTION: 196,
      SUNRAYS_WEIGHT: 1.0,
    })
  },
  methods: {
    openWindow(link) {
      window.open(link, '_blank');
    }
  },
}
</script>
