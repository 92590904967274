<template>
  <form
    class="p-8 md:p-14"
    @submit.prevent="formSubmit"
  >
    <fieldset
      v-if="!success"
      class="text-left pb-6"
    >
      <t-input
        v-model="form.name"
        placeholder="Your name"
        required
        :disabled="loading || success"
        @keydown="delete errors.name"
      />
      <p
        v-if="errors.name"
        class="inline-block bg-red-600 p-1 px-2 text-white text-xs mt-1 rounded-full"
      >
        {{ errors.name }}
      </p>
    </fieldset>
    <fieldset
      v-if="!success"
      class="text-left pb-6"
    >
      <t-input
        v-model="form.email"
        type="email"
        required
        placeholder="Your email"
        :disabled="loading || success"
        @keydown="delete errors.email"
      />
      <p
        v-if="errors.email"
        class="inline-block bg-red-600 p-1 px-2 text-white text-xs mt-1 rounded-full"
      >
        {{ errors.email }}
      </p>
    </fieldset>
    <fieldset
      v-if="!success"
      class="text-left pb-6"
    >
      <t-textarea
        v-model="form.message"
        placeholder="Message"
        required
        rows="8"
        :disabled="loading || success"
        @keydown="delete errors.message"
      />
      <p
        v-if="errors.message"
        class="inline-block bg-red-600 p-1 px-2 text-white text-xs mt-1 rounded-full"
      >
        {{ errors.message }}
      </p>
    </fieldset>
    <p
      v-if="errors.generic"
      class="inline-block bg-red-600 p-1 px-2 text-white text-xs mb-5 rounded-full"
    >
      {{ errors.generic }}
    </p>
    <div v-if="success">
      <div
        class="bg-green-700 p-3 text-left"
        role="alert"
      >
        <div class="flex">
          <div class="text-lg pr-2">
            👍
          </div>
          <div>
            <p class="font-bold">
              Thanks for the message!
            </p>
            <p class="text-sm">
              I'll reply to you very soon
            </p>
            <t-button
              type="button"
              class="mt-4 green"
              @click="$emit('close')"
            >
              Cheers
            </t-button>
          </div>
        </div>
      </div>
    </div>
    <t-button
      v-else
      class="pink"
      :class="{'loading': loading}"
      :disabled="loading"
    >
      Send message ✈️
    </t-button>
  </form>
</template>

<script>
export default {
  name: 'ContactForm',
  components: {
  },
  data() {
    return {
      loading: false,
      success: false,
      errors: {},
      form: {
        name: '',
        email: '',
        message: '',
      },
    };
  },
  methods: {
    formSubmit() {
      this.loading = true;

      this.$axios.post(
        process.env.VUE_APP_EMAIL_API,
        { ...this.form, site: this.$siteDomain }
      ).finally(() => {
        this.loading = false;
      }).then(() => {
        this.success = true;
        this.form = {};
      }).catch((e) => {
        if (e.response.data && e.response.data.errors) {
          this.errors = e.response.data.errors;
        } else {
          this.errors.generic = 'Something went wrong! Please try again';
        }
      });
    },
  }
}
</script>
